<template>
  <div>
    <h6 class="text-primary">
      <b>Growth</b>
    </h6>
    <b-row class="mb-1 align-items-center">
      <!-- <b-col cols="12" class="mb-1">
        <span><b>header</b></span>
      </b-col> -->
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-checkbox
          :checked="growth.status"
          disabled
        >
          ปกติ
        </b-form-checkbox>
      </b-col>
      <b-col cols="2">
        <b-form-checkbox
          :checked="!growth.status && growth.status != null"
          disabled
        >
          ผิดปกติ
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="3"
        class="d-flex flex-column"
      >
        <b-form-checkbox
          :checked="growth.sc1"
          disabled
        >
          {{ code1 }}
        </b-form-checkbox>
        <b-form-checkbox
          :checked="growth.sc2"
          disabled
        >
          {{ code2 }}
        </b-form-checkbox>
        <b-form-checkbox
          :checked="growth.sc3"
          disabled
        >
          {{ code3 }}
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="7"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ growth.note }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { growth: Object },
  data() {
    return {
      code1: 'Ht> 97 <p3',
      code2: 'Wt> 97 <p3',
      code3: 'OFC >97 <p3',
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
