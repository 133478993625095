<template>
  <div>
    <h6 class="text-primary">
      <b>Current Status</b>
    </h6>
    <b-row class="mb-1 align-items-center">
      <!-- <b-col cols="12" class="mb-1">
        <span><b>header</b></span>
      </b-col> -->
      <b-col
        cols="12"
        xl="2"
        class="mb-1 mb-xl-0"
      >
        <b-form-checkbox
          :checked="currentStatus.status"
          disabled
        >
          สบายดี
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="3"
        xl="2"
      >
        <b-form-checkbox
          :checked="!currentStatus.status && currentStatus.status != null"
          disabled
        >
          เจ็บป่วย
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="9"
        xl="8"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ currentStatus.comment }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { currentStatus: Object },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>

</style>
