<template>
  <div>
    <b-row
      v-for="(item, index) in recommend"
      :key="index"
      class="mb-ct-5"
    >
      <b-col cols="1">
        <b-form-checkbox
          :checked="item.status"
          disabled
        />
      </b-col>
      <b-col cols="11">
        {{ item.dataRecommendation }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { recommend: Array },
}
</script>

<style lang="scss" scoped>
  .mb-ct-5{
    margin-bottom: 5px;
  }
</style>
