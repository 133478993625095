<template>
  <div>
    <h6 class="text-primary">
      <b>Feeding</b>
    </h6>
    <b-row
      v-if="feeding.feedingSt"
      class="mb-1"
    >
      <b-col cols="2">
        <b-form-checkbox
          :checked="feeding.BF"
          disabled
        >
          BF
        </b-form-checkbox>
      </b-col>
      <b-col cols="2">
        <b-form-checkbox
          :checked="feeding.formula.status"
          disabled
        >
          Formula
        </b-form-checkbox>
      </b-col>
      <b-col cols="2">
        <div class="new-line tab-text txt-center">
          {{ feeding.formula.comment }}
        </div>
      </b-col>
      <span>OZx</span>
      <b-col cols="2">
        <div class="new-line tab-text txt-center">
          {{ feeding.formula.number }}
        </div>
      </b-col>
      <span>มื้อ/วัน</span>
    </b-row>
    <b-row
      v-if="feeding.solidFood != null"
      class="mb-1"
    >
      <b-col
        cols="2"
        lg="12"
        xl="2"
      >
        Solid food
      </b-col>
      <b-col
        cols="1"
        lg="2"
        xl="1"
      >
        <b-form-checkbox
          :checked="feeding.solidFood.number == '1'"
          disabled
        >
          1
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="1"
        lg="2"
        xl="1"
      >
        <b-form-checkbox
          :checked="feeding.solidFood.number == '2'"
          disabled
        >
          2
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="1"
        lg="2"
        xl="1"
      >
        <b-form-checkbox
          :checked="feeding.solidFood.number == '3'"
          disabled
        >
          3
        </b-form-checkbox>
      </b-col>
      <span>meals a day</span>
    </b-row>
    <b-row
      v-if="feeding.feedingProblem != null"
      class="mb-1 align-items-center"
    >
      <b-col
        cols="12"
        class="mb-1"
      >
        <span><b>ปัญหาการกิน</b></span>
      </b-col>
      <b-col
        cols="12"
        xl="2"
        class="mb-1 mb-xl-0"
      >
        <b-form-checkbox
          :checked="feeding.feedingProblem.status"
          disabled
        >
          WNL
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="3"
        xl="2"
      >
        <b-form-checkbox
          :checked="!feeding.feedingProblem.status && feeding.feedingProblem.status != null"
          disabled
        >
          Not WNL
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="9"
        xl="8"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ feeding.feedingProblem.comment }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { feeding: Object },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
  .new-line{
    width: 100%;
    white-space: pre-wrap;
  }
  .tab-text{
    text-indent: 15px;
  }
  .txt-center{
    text-align: center;
  }
</style>
