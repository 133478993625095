<template>
  <div>
    <h6 class="text-primary">
      <b>Guidances</b>
    </h6>
    <b-row
      v-for="(item, index) in guide.list"
      :key="index"
      class="mb-ct-5"
    >
      <b-col cols="1">
        <b-form-checkbox
          :checked="item.status"
          disabled
        />
      </b-col>
      <b-col cols="11">
        {{ item.dataGuidance }}
      </b-col>
    </b-row>
    <b-row class="mb-1 align-items-center">
      <b-col cols="12">
        <span><b>note</b></span>
      </b-col>
      <b-col
        cols="12"
        class="d-flex align-items-end"
      >
        <div class="new-line tab-text">
          {{ guide.note }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { guide: Object },
}
</script>

<style lang="scss" scoped>
  .mb-ct-5{
    margin-bottom: 5px;
  }
</style>
