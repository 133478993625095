<template>
  <div>
    <b-row>
      <b-col
        cols="5"
        class="d-flex align-items-center justify-content-end"
      >
        <b-form-checkbox
          :checked="vaccine.status"
          disabled
        >
          Advice
        </b-form-checkbox>
      </b-col>
      <b-col cols="7">
        <b-col class="mb-ct-5">
          <b-form-checkbox
            :checked="vaccine.vc1"
            disabled
          >
            {{ vacine1 }}
          </b-form-checkbox>
        </b-col>
        <b-col class="mb-ct-5">
          <b-form-checkbox
            :checked="vaccine.vc2"
            disabled
          >
            {{ vacine2 }}
          </b-form-checkbox>
        </b-col>
        <b-col class="mb-ct-5">
          <b-form-checkbox
            :checked="vaccine.vc3"
            disabled
          >
            {{ vacine3 }}
          </b-form-checkbox>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: { vaccine: Object },
  data() {
    return {
      vacine1: 'safety vaccine',
      vacine2: 'efficacy vaccine',
      vacine3: 'non-EPI vaccine',
    }
  },
}
</script>

<style lang="scss" scoped>
  .mb-ct-5{
    margin-bottom: 5px;
  }
</style>
