<template>
  <div v-if="!generalFlag">
    <b-tabs
      content-class="mt-1"
      fill
      lazy
    >
      <b-tab
        title="All"
        active
      >
        <b-row class="w-100 mb-2 mt-2">
          <b-col cols="12">
            <vitalSign
              :key="5"
              service="5"
              :txn="txn"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="pr-custom-5"
          >
            <!-- <b-card class="custom-general mb-1"> -->
            <encounter
              :encounter="encounter"
              class="mb-2"
            />
            <!-- <past-history :history="history" cl4ass="mb-2"></past-history> -->
            <currentStatus :current-status="currentStatus" />
            <growth :growth="growth" />
            <feeding :feeding="feeding" />
            <elimination :eliminations="eliminations" />
            <development :development="development" />
            <recommend :recommend="recommend" />
            <guide :guide="guide" />
            <vaccine :vaccine="vaccine" />
            <physical-examination
              :physical-examination="physicalExamination"
              class="mb-2"
            />
            <!-- </b-card> -->
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="pl-custom-5"
          >
            <icd10 :i-c-d10="ICD10" />
            <order :order="order" />
            <followup />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Record">
        <encounter
          :encounter="encounter"
          class="mb-2"
        />
        <!-- <past-history :history="history" class="mb-2"></past-history> -->
        <currentStatus :current-status="currentStatus" />
        <growth :growth="growth" />
        <feeding :feeding="feeding" />
        <elimination :eliminations="eliminations" />
        <development :development="development" />
        <recommend :recommend="recommend" />
        <guide :guide="guide" />
        <vaccine :vaccine="vaccine" />
        <physical-examination
          :physical-examination="physicalExamination"
          class="mb-2"
        />
      </b-tab>
      <b-tab title="ICD10">
        <icd10 :i-c-d10="ICD10" />
      </b-tab>
      <b-tab title="Order">
        <order :order="order" />
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <b-tabs
      content-class="mt-1"
      fill
      lazy
    >
      <b-tab
        title="All"
        active
      >
        <b-row class="w-100 mb-2 mt-2">
          <b-col cols="12">
            <vitalSign
              :key="5"
              service="5"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="pr-custom-5"
          >
            <!-- <b-card class="custom-general mb-1"> -->
            <encounter
              :encounter="encounter"
              class="mb-2"
            />
            <past-history
              :history="history"
              class="mb-2"
            />
            <physical-examination
              :physical-examination="physicalExamination"
              class="mb-2"
            />
            <eliminations />
            <!-- </b-card> -->
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="pl-custom-5"
          >
            <icd10 :i-c-d10="ICD10" />
            <order :order="order" />
            <followup />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Record">
        <encounter
          :encounter="encounter"
          class="mb-2"
        />
        <past-history
          :history="history"
          class="mb-2"
        />
        <physical-examination
          :physical-examination="physicalExamination"
          class="mb-2"
        />
        <eliminations />
      </b-tab>
      <b-tab title="ICD10">
        <icd10 :i-c-d10="ICD10" />
      </b-tab>
      <b-tab title="Order">
        <order :order="order" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import followup from './components/followup.vue'
import encounter from './components/encounter.vue'
import pastHistory from './components/pastHistory.vue'
import physicalExamination from './components/physicalExamination.vue'
import icd10 from './components/ICD10.vue'
import order from './components/order.vue'
import currentStatus from './components/well-baby/currentStatus.vue'
import growth from './components/well-baby/growth.vue'
import feeding from './components/well-baby/feeding.vue'
import elimination from './components/well-baby/elimination.vue'
import development from './components/well-baby/developments.vue'
import recommend from './components/well-baby/recommend.vue'
import guide from './components/well-baby/guidances.vue'
import vaccine from './components/well-baby/vaccine.vue'
import vitalSign from './components/vitalSign.vue'

export default {
  components: {
    followup,
    encounter,
    pastHistory,
    physicalExamination,
    icd10,
    order,
    currentStatus,
    growth,
    feeding,
    elimination,
    development,
    recommend,
    guide,
    vaccine,
    vitalSign,
  },
  props: {
    txn: {
      type: undefined,
      default: () => '',
    },
    hn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      generalFlag: false,
      encounter: { chiefComplaint: '', presentIllness: '', nurseC: '' },
      history: null,
      physicalExamination: { list: [], note: '' },
      image: null,
      ICD10: [],
      order: { list: {}, note: '' },
      development: { list: [], note: '' },
      recommend: [],
      guide: { list: [], note: '' },
      currentStatus: {},
      growth: {
        status: null,
        sc1: false,
        sc2: false,
        sc3: false,
        note: '',
      },
      vaccine: {
        status: false,
        vc1: false,
        vc2: false,
        vc3: false,
      },
      eliminations: [],
      feeding: {
        BF: null,
        feedingSt: null,
        formula: {},
        solidFood: null,
        feedingProblem: null,
      },
    }
  },
  async created() {
    await this.fetchICD10()
    await this.fetchOrder()
    await this.fetchRecord()
  },
  methods: {
    async fetchOrder() {
      await this.$http({
        url: `/api/EMR/opd/pt_message/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        [this.order.note] = data.data_pt.msg
        this.order.note = JSON.parse(this.order.note)
        // console.log('note', this.order.note)
      })
      await this.$http({
        url: `/api/EMR/opd/lists/${this.txn}`,
        method: 'GET',
        data: {
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.order.list = data
        // console.log('order', this.order.list)
      })
    },
    async fetchICD10() {
      await this.$http({
        url: 'api/EMR/FindNewTxnICD',
        method: 'POST',
        data: {
          TXN: this.txn,
        },
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        this.ICD10 = data
        // console.log('icd10', data)
      })
    },
    async fetchRecord() {
      await this.$http({
        url: 'api/EMR/allWBBForm',
        method: 'POST',
        data: {
          // PatientID: this.hn == null ? parseInt(this.$store.getters.getPdetail.hn) : parseInt(this.hn),
          PatientID: parseInt(this.hn, 10),
          ServiceID: 0,
          // "ServiceID" : this.$store.getters.getNewpatientdata.nurseQueueDetail.serviceID,
          // "TXN" : parseInt(this.$store.getters.getPdetail.txn)
          TXN: parseInt(this.txn, 10),
        },
      }).then(({ data }) => {
        if (data.form === 'G') {
          if (data.patientHistoryFormRecord.nursePatientHistory !== null) {
            this.encounter.nurseC = data.patientHistoryFormRecord.nursePatientHistory.chiefComplaint
          }
          if (data.patientHistoryFormRecord.doctorPatientHistory !== null) {
            this.encounter.chiefComplaint = data.patientHistoryFormRecord.doctorPatientHistory.chiefComplaint
            this.encounter.presentIllness = data.patientHistoryFormRecord.doctorPatientHistory.presentIllness
          }
          this.history = data.historyFormRecord
          this.physicalExamination.list = data.peFormRecord.pE_Checkbox
          this.physicalExamination.note = data.peFormRecord.pE_Text[0].comment
          // this.images = data.drawingFormRecord
          this.generalFlag = true
        } else {
          if (data.patientHistoryFormRecord.nursePatientHistory !== null) {
            this.encounter.nurseC = data.patientHistoryFormRecord.nursePatientHistory.chiefComplaint
          }
          if (data.patientHistoryFormRecord.doctorPatientHistory !== null) {
            this.encounter.chiefComplaint = data.patientHistoryFormRecord.doctorPatientHistory.chiefComplaint
            this.encounter.presentIllness = data.patientHistoryFormRecord.doctorPatientHistory.presentIllness
          }

          this.development.list = data.developmentFormRecord.developmentFetchList
          this.development.note = data.developmentFormRecord.note.commen
          this.recommend = data.recommendationFormRecord
          this.guide.list = data.guidanceFormRecord.guidanceFetchList
          this.guide.note = data.guidanceFormRecord.note.comment
          this.physicalExamination.list = data.peFormRecord.pE_Checkbox
          // this.T = data.peFormRecord.pE_Text.find(e => e.dataPE == "T")
          // this.AF = data.peFormRecord.pE_Text.find(e => e.dataPE == "AF")
          // this.PF = data.peFormRecord.pE_Text.find(e => e.dataPE == "PF")
          this.physicalExamination.note = data.peFormRecord.pE_Text.find(e => e.dataPE === 'Other').comment

          this.currentStatus = data.wellBabyFormRecord.find(e => e.dataWellBaby === 'Current status')

          const listGrowth = data.wellBabyFormRecord.filter(e => e.dataWellBaby === 'Growth')
          const growths = listGrowth.find(e => e.status === true)

          if (growths != null) {
            this.growth.status = true
          } else {
            this.growth.status = listGrowth.find(e => e.status === false) != null ? false : null
            this.growth.sc1 = listGrowth.find(e => e.comment === 'Ht> 97 <p3') != null
            this.growth.sc2 = listGrowth.find(e => e.comment === 'Wt> 97 <p3') != null
            this.growth.sc3 = listGrowth.find(e => e.comment === 'OFC >97 <p3') != null
            const growthNote = listGrowth.find(e => e.comment !== 'Ht> 97 <p3' && e.comment !== 'Wt> 97 <p3' && e.comment !== 'OFC >97 <p3')
            this.growth.note = growthNote !== null ? growthNote.comment : null
          }

          const listVaccine = data.wellBabyFormRecord.filter(e => e.dataWellBaby === 'Vaccination')
          const vaccineSt = listVaccine.find(e => e.status === true)
          if (vaccineSt != null) {
            this.vaccine.status = true
            this.vaccine.vc1 = listVaccine.find(e => e.comment === 'safety vaccine') !== null
            this.vaccine.vc2 = listVaccine.find(e => e.comment === 'efficacy vaccine') !== null
            this.vaccine.vc3 = listVaccine.find(e => e.comment === 'non-EPI vaccine') !== null
          }
          // console.log('well-baby', vaccineSt)
          const bf = data.feedingFormRecord.find(e => e.dataFeeding === 'BF')
          this.feeding.BF = bf.status
          this.feeding.formula = data.feedingFormRecord.find(e => e.dataFeeding === 'Formula')
          this.feeding.feedingSt = this.BF !== null && this.formula !== null
          this.feeding.solidFood = data.feedingFormRecord.find(element => element.dataFeeding === 'Solid Food')
          this.feeding.feedingProblem = data.feedingFormRecord.find(e => e.dataFeeding === 'Feeding problem')

          this.eliminations = data.eliminationFormRecord

          // this.stickers = data.stickerFormRecord

          // this.images = data.drawingFormRecord

          // this.finished = true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
